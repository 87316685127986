require('./tags/angular-dropdowns.js');

// This is in a separate file to make sure that when angular-dropdown is lazy loaded, the overriding templates get into effect only after the lib is loaded (And not the other way around)

// ng-annotate not run by default on vendor libs, so make sure to annotate
angular.module('ngDropdowns').run(['$templateCache', function ($templateCache) {
    $templateCache.put('ngDropdowns/templates/dropdownSelect.html', [
        '<div ng-class="{\'disabled\': dropdownDisabled}" class="wrap-dd-select" tabindex="0">',
        '   <span ng-hide="dropdownModel.icon" class="selected">{{dropdownModel[labelField] | translate}}</span> ',
        '   <span ng-if="dropdownModel.icon" class="selected"><i ng-class="dropdownModel.icon"></i></span>',
        '   <i class="fa fa-angle-down pull-right"></i>',
        '    </span>',
        '   <span class="dropdown-date" ng-show="dropdownModel.start_date && dropdownModel.end_date">{{dropdownModel.start_date}} - {{dropdownModel.end_date}}</span>',
        '   <ul class="dropdown">',
        '      <li ng-repeat="item in dropdownSelect" class="dropdown-item" dropdown-select-item="item" dropdown-item-label="labelField"></li>',
        '   </ul>',
        '</div>'
    ].join(''));

    $templateCache.put('ngDropdowns/templates/dropdownSelectItem.html', [
        '<li ng-class="{\'tag-group\': dropdownSelectItem.isTagGroup, divider: (dropdownSelectItem.divider && !dropdownSelectItem[dropdownItemLabel]), \'divider-label\': (dropdownSelectItem.divider && dropdownSelectItem[dropdownItemLabel])}">',
        '   <a href="" class="dropdown-item" ng-if="!dropdownSelectItem.divider" ng-href="{{dropdownSelectItem.href}}" ng-click="selectItem()">',
        '       <span ng-hide="dropdownSelectItem.icon" class="dropdown-name" title="{{::dropdownSelectItem[dropdownItemLabel] | translate }}">{{::dropdownSelectItem[dropdownItemLabel] | translate }}</span> ',
        '       <i ng-if="dropdownSelectItem.icon" class="dropdown-name" ng-class="dropdownSelectItem.icon"></i>',
        '       <span class="dropdown-date" ng-show="dropdownSelectItem.start_date && dropdownSelectItem.end_date">{{dropdownSelectItem.start_date}} - {{dropdownSelectItem.end_date}}</span>',
        '   </a>',
        '   <span ng-if="dropdownSelectItem.divider">{{::dropdownSelectItem[dropdownItemLabel] | translate}}</span>',
        '</li>'
    ].join(''));
}]);