// This file is the index for the vendor build
// It include()s JS files required for the entire app - caching them in a seperate JS and allowing faster rebuilds
// Because it only include()s them - they aren't actually run until the main app require()s them (and incurring the load time cost)
// However, it does increase the iniital page load size

// TODO: Think if there are more modules which we believe should be here

// jQuery first
require.include('expose-loader?$!expose-loader?jQuery!jquery');
require.include('jquery-migrate'); // TODO: Remove jquery-migrate

// Third party JS libraries
require.include('lodash');
require.include('expose-loader?riveted!exports-loader?riveted!./riveted.js');
require.include('expose-loader?moment!moment-timezone');
require.include('papaparse');

// Now Angular and Angular-third party scripts
require.include('angular');
require.include('angular-route');
require.include('./vendor/ui-bootstrap-tpls-1.3.1.js');
require.include('angular-sanitize');

require.include('./vendor/alerts/angular-toastr.js');
require.include('./vendor/loading-bar.js');
require.include('./vendor/angular-translate.js');
require.include('./vendor/hotkeys/hotkeys.js');
require.include('angular-cache');
require.include('oclazyload');

require.include('./vendor/autocomplete/angucomplete-alt.js');
require.include('./vendor/singular-angular-dropdowns.js');
require.include('./vendor/singular-ng-tags-input.js');
require.include('angular-drag-and-drop-lists');

// React
require.include('react');
require.include('react-dom');

// CSS files - due to the small size and low run cost, include all of them here for all the vendor libraries
// Add your 3rd party CSS files here

// TODO: Check if we can use async loaded CSS files to move this to where the libraries themselves are required() for ones not included() above
require('../css/vendor/jquery.tagbox.css');
require('../css/vendor/datatables.bootstrap.min.css');
require('../css/vendor/jquery.jqplot.min.css');
require('../css/vendor/jquery.tablesorter.pager.css');
require('../css/vendor/loading-bar.css');
require('../css/vendor/jquery.dataTables.min.css');
require('../css/vendor/table/ng-table.css');
require('../css/vendor/daterangepicker.css');
require('../css/vendor/hotkeys/hotkeys.css');
require('../css/vendor/alerts/angular-toastr.css');
require('../css/vendor/angular-switch.css');
require('../css/vendor/autocomplete/angucomplete-alt.css');
require('../css/vendor/autocomplete/angular-timezone-selector.css');
require('../css/vendor/tags/ng-tags-input.css');
require('../css/vendor/tags/angular-dropdowns.css');
require('../css/vendor/angular-gridster.min.css');
require('../css/vendor/morris-0.5.1.css');
require('../css/vendor/jquery.gridster.css');
require('../css/vendor/rzslider.css');
require('../css/vendor/handsontable/handsontable.full.css');

require('../css/ui.theme.css');
require('../css/csdash/typeahead.css');
require('../css/jquery.tagsinput.css');
require('../css/vendor/font-awesome-4.7.0/css/font-awesome.min.css');
require('../css/flag-icon.css');
require('../css/tipTip.css');
